import { CommonService } from "@sk/services";
import { useEffect } from "react";

const useSetPageInfo = ({ title }) => {
  useEffect(() => {
    CommonService.updatePageTitle(title);
    CommonService.updateAppTitle(title + " - Storeking ERP");
  }, [title]);
};

export default useSetPageInfo;

import {
  AuthService,
  BrandService,
  CategoryService,
  DealService,
  EmployeeService,
  FranchiseService,
  OmsService,
  ProductService,
  SellerService,
  UserService,
  VendorService,
} from "@sk/services";
import get from "lodash/get";
import { useCallback } from "react";

function useAttachAdditionalData() {
  const extractIds = (data, key, useKeyPath = false) => {
    let ids = [];
    (data || []).forEach((x) => {
      const v = useKeyPath ? get(x, key, "") : x[key];
      if (Array.isArray(v)) {
        ids = ids.concat(v);
      } else {
        ids.push(v);
      }
    });
    return Array.from(new Set(ids));
  };

  const apiCaller = async (data, ids, config, callback) => {
    const api = config.api || "";

    if (!ids.length) {
      callback({ data, res: [], config });
      return;
    }

    try {
      if (api === "user") {
        const r = await UserService.getList(
          config.filter(
            ids.filter((x) => x && ["System", "Admin", "grn"].indexOf(x) == -1)
          )
        );
        callback({ data, config, res: r?.resp || [] });
      } else if (api === "employee") {
        const r = await EmployeeService.getEmployeeList(config.filter(ids));
        callback({ data, config, res: r?.resp || [] });
      } else if (api == "vendor") {
        const r = await VendorService.getVendors(config.filter(ids));
        callback({ data, config, res: r?.resp || [] });
      } else if (api == "product") {
        const r = await ProductService.getList(config.filter(ids));
        callback({ data, config, res: r?.resp || [] });
      } else if (api === "category") {
        const r = await CategoryService.getList(config.filter(ids));
        callback({ data, config, res: r?.resp || [] });
      } else if (api === "brand") {
        const r = await BrandService.getList(config.filter(ids));
        callback({ data, config, res: r?.resp || [] });
      } else if (api === "deal") {
        const r = await DealService.getDeals(config.filter(ids));
        callback({ data, config, res: r?.resp || [] });
      } else if (api === "dealStock") {
        const r = await DealService.getBulkDealStock(
          config.whId,
          config.filter(ids)
        );
        callback({ data, config, res: r?.resp || [] });
      } else if (api === "seller") {
        const r = await SellerService.getList(config.filter(ids));
        callback({ data, config, res: r?.resp?.sellers || [] });
      } else if (api === "franchise") {
        const r = await FranchiseService.getFranchises(config.filter(ids));
        callback({ data, config, res: r?.resp || [] });
      } else {
        callback({ data, config, res: [] });
      }
    } catch (error) {
      callback({ data, key: config, res: [] });
    }
  };

  const setAdditionalData = useCallback((data = [], keys = [], callback) => {
    keys.forEach((x) => {
      const ids = extractIds(data, x.key, x.useKeyPath);
      apiCaller(data, ids, x, callback);
    });
  }, []);

  function attachAdditionalData(res, dataList, config) {
    let data = JSON.parse(JSON.stringify(dataList));
    (data || []).forEach((x) => {
      if (config?.loadingKey) {
        x[config.loadingKey] = false;
      }
      const v = config.useKeyPath ? get(x, config.key, "") : x[config.key];
      if (Array.isArray(v)) {
        let k = [];
        v.forEach((c) => {
          const t =
            (res || []).find((e) => e[config.matchKey || "_id"] === c) || {};
          k.push(config?.valuePath ? get(t, config?.valuePath) : t);
        });
        x[config?.dataKey] = k;
      } else {
        const t =
          (res || []).find((e) => e[config.matchKey || "_id"] === v) || {};
        x[config?.dataKey] = config?.valuePath ? get(t, config?.valuePath) : t;
      }
    });
    return data;
  }

  const attachAllData = useCallback((primaryData, attachedData) => {
    let t = [...primaryData];
    [...attachedData].forEach((x) => {
      let formattedData = attachAdditionalData(
        x.res,
        [...primaryData],
        x.config || {}
      );
      t.forEach((e) => {
        const index = formattedData.findIndex((f) => f._id == e._id);
        if (index != -1) {
          e[x.config.dataKey] = formattedData[index][x.config.dataKey];
          if (x.config.loadingKey) {
            e[x.config.loadingKey] = false;
          }
        }
        if (x.config.loadingKey) {
          e[x.config.loadingKey] = false;
        }
        return e;
      });
    });
    return t;
  }, []);

  return [setAdditionalData, attachAllData];
}

export default useAttachAdditionalData;

import { AuthService } from "@sk/services";

const useAuth = () => {
  const isLoggedIn = AuthService.isUserLoggedIn();
  return {
    isLoggedIn,
  };
};

export default useAuth;
